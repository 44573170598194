import React from 'react';
import PropTypes from 'prop-types';
import { ImageFallback } from './components/ImageFallback';
import { ImageNoScript } from './components/ImageNoScript';

export const ImageSimple = ({
  src,
  alt,
  width,
  height,
  loading,
  hideNoscriptImage
}) => {
  return (
    <picture>
      <ImageFallback
        url={src}
        alt={alt}
        width={width}
        height={height}
        loading={loading}
      />
      {/*
      Avoid Preact from rendering elements inside noscript tags
      @see https://github.com/preactjs/preact/issues/2797
      */}
      {!hideNoscriptImage && typeof window === 'undefined' && (
        <ImageNoScript
          url={src}
          alt={alt}
          width={width}
          height={height}
          loading={loading}
        />
      )}
    </picture>
  );
};

ImageSimple.defaultProps = {
  alt: undefined,
  width: undefined,
  height: undefined,
  loading: 'lazy',
  hideNoscriptImage: false
};

ImageSimple.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  loading: PropTypes.oneOf(['eager', 'lazy']),
  hideNoscriptImage: PropTypes.bool
};
